import React, { useState } from 'react'
// import { Link } from 'gatsby'
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { heartIcon } from '../components/customIcons'
import { ringsIcon } from '../components/customIcons'
import { oarsIcon } from '../components/customIcons'
// import Layout from '../components/layout'
// import SEO from '../components/seo'
import '../styles/main.scss'
import Button from '@material-ui/core/Button'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded'
import LibraryMusicRoundedIcon from '@material-ui/icons/LibraryMusicRounded'
import IconButton from '@material-ui/core/IconButton'
// import DeleteIcon from '@material-ui/icons/Clear'
// import AddIcon from '@material-ui/icons/Add'
import { StaticImage } from 'gatsby-plugin-image'
import Gallery from './photogallery'

const location_Center = [47.68209942028788, 11.188467852838494]
const location_SV = [47.67081, 11.20295]
const location_BH = [47.68691, 11.18016]
const location_WO = [47.68651976719784, 11.158479962074225]

const renderLoginForm = (
  login,
  usernameVal,
  setUsernameVal,
  passwordVal,
  setPasswordVal,
  loginErrorMsg,
) => (
  <div className='loginpage'>
    <StaticImage
      className='LooPLogo'
      src='../images/LooP-white.svg'
      alt='The LooP Logo'
    />
    <form
      className='loginform'
      onSubmit={e => {
        e.preventDefault()
        login(usernameVal, passwordVal)
      }}
    >
      <input
        className='logincontent'
        type='username'
        name='username'
        id='username'
        required
        placeholder='username'
        value={usernameVal}
        onChange={e => setUsernameVal(e.target.value)}
      ></input>
      <br></br>

      <input
        className='logincontent'
        type='password'
        id='user-pw'
        name='user-pw'
        required
        placeholder='password'
        value={passwordVal}
        onChange={e => setPasswordVal(e.target.value)}
      ></input>

      {loginErrorMsg && (
        <p style={{ color: 'red', margin: 0, padding: 0 }}>{loginErrorMsg}</p>
      )}

      {!loginErrorMsg && <br></br>}
      <Button
        variant='outlined'
        color='primary'
        className='logincontent'
        type='submit'
        style={{ width: '100%' }}
      >
        enter the LooP
      </Button>
    </form>
  </div>
)

const renderPageContent = (
  logout,
  // bigOnesCount,
  // setBigOnesCount,
  // littleOnesCount,
  // setLittleOnesCount,
) => (
  <div>
    <div className='hero'>
      <StaticImage
        className='LooPLogo'
        src='../images/LooP-white.svg'
        alt='The LooP Logo'
      />
      <h2>22. - 24.7.2022</h2>
    </div>
    <div className='whiteDiv'>
      <div className='divContent'>
        <h1>Hurrah, wir heiraten!</h1> <br></br>
        <p className='box1'>
          Liebe Gäste,<br></br>
          <br></br>zu unserer Hochzeit am Wochenende des 23. Juli 2022 laden wir
          euch ganz herzlich ins schöne Blaue Land nach Murnau am Staffelsee
          ein. Gebt uns bitte zeitnah Bescheid,{' '}
          <b>ob und wie und wie viele der Tage </b>ihr mit uns feiern wollt.
          <br></br>
          Wir freuen uns auf ein rauschendes Fest bei bestem Wetter und ohne
          jegliche Einschränkungen. Kommt gerne immer wieder hier auf die Seite
          zurück, um auf dem neuesten Stand zu bleiben. Und dann gibt es auch
          die FAQs, die wir mit jeder neuen Frage brav erweitern werden.
          <br></br>
          <br></br>Wir freuen uns sehr auf euch!<br></br>
          <br></br>Liebe Grüße, Larissa und Paul
        </p>
      </div>
    </div>

    <div className='roseDiv'>
      <div className='divContent'>
        <h1>Tagesablauf</h1>
        <p className='sub-p'>
          Das hier ist unser Wunschprogramm. Wir würden uns freuen, wenn
          möglichst viele von euch das ganze Wochenende von Freitag bis Sonntag
          mit uns verbringen können. Geheiratet wird am Samstag, so richtig
          offiziell und schick ab Bootsabfahrt - spätestens da muss jeder von
          euch fesch aufgemaschelt am Start sein.
        </p>
        <div className='two'>
          <div className='box2'>
            <table className='schedule'>
              <thead>
                <tr>
                  <th colspan='2'>Freitag</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>16:00</td>
                  <td>
                    ab hier gemütliches Ankommen für alle die Freitag schon
                    anreisen können
                  </td>
                </tr>
                <tr>
                  <td>18:00</td>
                  <td>ab hier entspanntes Grillen im Garten</td>
                </tr>
                {/* <tr>
                  <td>19</td>
                  <td>Essen & (Be-)Trinken</td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className='box2'>
            <table>
              <thead>
                <tr>
                  <th colspan='2'>Samstag</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>8:30 bis 10:00</td>
                  <td>
                    kleines Frühstück in der Villa (für Übernachtende auf dem
                    Grundstück)
                  </td>
                </tr>
                <tr>
                  <td>10:30</td>
                  <td>
                    Start am Bootsverleih (Wir würden euch bitten, pünktlich am
                    Anleger zu erscheinen, da wir gerne gemeinsam starten
                    wollen.) - jetzt geht’s in die Ruderboote
                  </td>
                </tr>
                <tr>
                  <td>danach</td>
                  <td>Trauzeremonie auf der Insel Wörth</td>
                </tr>
                <tr>
                  <td>15:00</td>
                  <td>
                    Villa Reinherz: Hochzeitstorte und Kaffee oder halt Bier und
                    sippin' Aperol auf dem Grundstück. Alternativ ein wenig Zeit
                    die Füße hochzulegen.
                  </td>
                </tr>
                <tr>
                  <td>18:00</td>
                  <td>Abendessen - Es wird gegessen, gefeiert und getanzt</td>
                </tr>
                <tr>
                  <td>24:00</td>
                  <td>
                    {
                      'Mitternachtssnack -> laute Musik muss hier leider sehr früh abgeschaltet werden, daher der frühe Beginn - wir werden aber weiterfeiern!'
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='box2'>
            <table>
              <thead>
                <tr>
                  <th colspan='2'>Sonntag</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>9:30</td>
                  <td>
                    großer Brunch im Garten (Villa Reinherz), mit Mimosas,
                    Ratsch und Tratsch in der Sonne
                  </td>
                </tr>
                <tr>
                  <td>15:00</td>
                  <td>time to say goodbye</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <StaticImage
        className='LooPWebPic'
        // src="../images/TitleImage-Tragerl.jpg"
        src='../images/LooP-web-pic-new.jpg'
        // height={600}
        alt='The LooP Pic'
        style={{ minWidth: '800px', verticalAlign: 'center' }}
      />
    </div>
    <div className='blueDiv'>
      <div className='divContent'>
        <h1>Locations</h1>
        <p className='sub-p'>
          Hier findet ihr Details zu den einzelnen Locations sowie
          Anreise-Infos.
        </p>
        <div className='two'>
          <div className='box1'>
            <h5>Villa Reinherz</h5>
            <p>
              Die Villa Reinherz liegt am Rande des Seidl Parks und gehört einer
              Stiftung. Wir dürfen die gesamte Villa samt Anlage nutzen. Hier
              werden wir die meiste Zeit verbringen, entspannen, feiern und
              hoffentlich einige von euch sogar übernachten. Erreichen könnt ihr
              sie einfach mit dem Auto (das Internet hilft) oder mit einem
              kurzen Fußweg vom Bahnhof Murnau. Wenn ihr mehr über die Villa und
              die Stiftung erfahren wollt, schaut{' '}
              <a href='http://www.zauner-stiftung.de' target='_blank'>
                hier
              </a>{' '}
              vorbei.
            </p>
          </div>
          <div className='box1'>
            <h5>Bootsverleih Seehausen</h5>
            <p>
              Am Samstagvormittag treffen wir uns alle am Bootsverleih Seehausen
              (Einen Parkplatz - und öffentliche Toiletten - findet ihr direkt
              nebenan). Von der Villa 40min zu Fuß oder 10min mit dem Auto. Hier
              geht es pünktlich in die Ruderboote - für alle Ruderwilligen gilt
              ab hier: Ärmel hochkrempeln und zeigen was ihr draufhabt. Aber
              keine Sorge, Luftlinie ist es kein halber Kilometer bis zur Insel.
            </p>
          </div>
          <div className='box1'>
            <h5>Wörth</h5>
            <p>
              Auf der Insel Wörth werden wir, gemeinsam mit euch, eine freie
              Trauzeremonie feiern und danach den sicherlich strahlenden
              Sommertag genießen. Für euer leibliches Wohl wird gesorgt sein.
              Wer nicht auf dem Boden sitzen kann oder möchte, ist herzlich
              eingeladen einen Klappstuhl mitzubringen. Disclaimer: auf die
              Insel kommt man nur mit dem Boot und es gibt keine Toiletten auf
              der Insel.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <MapContainer
          center={location_Center}
          zoom={14}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution=''
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <Marker position={location_SV} icon={heartIcon}>
            <Popup>
              Villa Reinherz, <br></br> Am Kapferberg 1, <br></br> 82418 Murnau
              am Staffelsee
            </Popup>
          </Marker>
          <Marker position={location_BH} icon={oarsIcon}>
            <Popup>
              Bootsverleih Seehausen, <br></br> Johannisstraße, <br></br> 82418
              Murnau am Staffelsee
            </Popup>
          </Marker>
          <Marker position={location_WO} icon={ringsIcon}>
            <Popup>
              Insel Wörth, <br></br> 82418 Murnau am Staffelsee
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>

    <div className='lightDiv'>
      <div className='divContent'>
        <h1>Unterkunft</h1>
        <p className='sub-p'>
          Wir werden uns die meiste Zeit auf dem Gelände der Villa Reinherz
          aufhalten und das Gelände nur für einen gemeinsamen Bootausflug
          verlassen. Am liebsten hätten wir euch alle in dieser wunderschönen
          Bubble, auf einem Platz und würden gemeinsam mit euch ein
          Hochzeitsfestival feiern. Übernachtung, Feiern, Abhängen - alles ohne
          das Wochenende verlassen zu müssen. Die Villa ist leider nicht groß
          genug um euch alle zu beherbergen. Sprecht aber gerne mit euch was wir
          euch ermöglichen können und welchen Level of Comfort ihr gerne hättet.
          <br></br>
          <br></br>Das hier sind die Möglichkeiten:
        </p>
        <div className='two'>
          <div className='box1'>
            <div className='picWrapper'>
              <StaticImage
                src='../images/icon-heart-tent.png'
                alt='A tent'
                // layout="constrained"
                // width={300}
              />
            </div>
            <br></br>
            <h5> Im Zelt</h5>
            <p>
              Zelten ist was tolles - und man muss das ja nicht mehr auf der
              harten Isomatte machen. Ein Familienzelt, eine Matratze und die
              eigene Decke: und fertig ist das Glampingerlebnis! Wir haben auf
              dem gesamten Gelände rund um die Villa genug Platz um uns
              auszubreiten. Wir werden eine Wiese hinter dem Haus vorbereiten.
              Mit Pfaden, Lampen und Strom für jeden Glamper.
            </p>
          </div>
          <div className='box1'>
            <div className='picWrapper'>
              <StaticImage src='../images/icon-heart-bus.png' alt='A camper' />
            </div>
            <br></br>
            <h5> In meinem Camper</h5>
            <p>
              Einige von euch erzählen immer stolz vom eigenen Camper oder
              zumindest dem ausgebauten Bus. Wollt ihr damit anreisen, gilt
              natürlich das gleiche wie für die Glamper. Leider dürfen die nicht
              in den Garten der Villa fahren, vor dem Zaun gibt es aber einige
              Parkplätze die in der Theorie zum Grundstück gehören (first
              come..). Und dann gibt es noch einen wunderschönen Campingplatz
              direkt am See - auch wenn ihr da nur zum Schlafen hintorkeln
              werdet.
            </p>
          </div>
          <div className='box1'>
            <div className='picWrapper'>
              <StaticImage src='../images/icon-heart-hotel.png' alt='A hotel' />
            </div>
            <br></br>
            <h5>Nobel im Hotel</h5>
            <p>
              Alle die zu alt oder zu bequem für Zelte und Camper sind, müssen
              sich eine Unterkunft in der Umgebung suchen. Je näher, desto
              besser. Durch die Passionsspiele in Oberammergau sind Hotels
              jedoch schon seit über einem Jahr gebucht, rar und teuer. Es gibt
              aber noch einige Ferienwohnungen oder AirBnBs in der Nähe der
              Location. Seid daher kreativ, tut euch zusammen und bucht
              gemeinsam.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className='roseDiv'>
      <div className='divContent'>
        <h1>{'Q&A'}</h1>
        <p>viele Fragen, noch mehr Antworten</p>
        <table className='sub-p'>
          <tbody>
            <tr>
              <td>Kinder</td>
              <td>
                Wir wissen wie schwierig die Frage sein kann - mit oder ohne
                Kindern feiern? Wir lieben eure Kinder sehr, wollen euch an
                unserer Hochzeit aber -ganz egoistisch- für uns haben, deshalb
                kommt wenn möglich ohne die Kleinen. Aber redet mit uns ob und
                wie und was für Möglichkeiten es gibt.{' '}
              </td>
            </tr>
            <tr>
              <td>Kleidung</td>
              <td>
                Generell für Samstag: Lockere, sommerlich festliche Kleidung.
                Wir wollen tanzen und laufen in der Wiese, rudern und baden im
                See, feiern auf der Insel. Für die Zeremonie dürft ihr eine
                festliche, bequeme Sommerkleidung mit Badekleidung kombinieren.
              </td>
            </tr>
            <tr>
              <td>Essen</td>
              <td>
                Solltet ihr Sonderwünsche bzgl. des Essens haben (Veggie, Vegan,
                Laktoseintoleranz, etc..) lasst es uns wissen!
              </td>
            </tr>
            <tr>
              <td>Geschenke</td>
              <td>
                Das größte Geschenk von allen ist für uns, dieses wundervolle
                Wochenende mit Euch zusammen zu feiern. Wenn Ihr unsere
                Flitterwochen dennoch unterstützen wollt, würden wir uns über
                einen Obolus hierzu sehr freuen.
              </td>
            </tr>
            <tr>
              <td>Freitag, Samstag oder Sonntag?</td>
              <td>
                Am besten natürlich alle drei Tage! Wir wollen das ganze
                Wochenende mit euch feiern. Nur in Ausnahmen (Kinder, mies lange
                Anreise, Feierallergie...) steht euch natürlich frei, den
                Freitag oder Sonntag unter Tränen abzusagen.
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className='whiteDiv'>
      <div className='divContent'>
        <h1>{'RSVP & Kontakt'}</h1>
        <p className='sub-p'>
          Bitte gebt uns <b>bis Ende März</b> Rückmeldung ob ihr mit uns feiert,
          wer kommt, welche dietären Restriktionen wir berücksichtigen müssen,
          wo ihr nächtigen wollt, wann ihr an- und abreisen wollt und bei
          welchen Frühstücken ihr dabei sein wollt. Wir freuen uns!
        </p>
        <div className='two'>
          <Button
            variant='outlined'
            size='large'
            startIcon={<SendRoundedIcon />}
            href='mailto:icke@cometotheloop.de?body=My custom mail body'
          >
            Dabei!
          </Button>
          {/* <Button
            variant='outlined'
            type='submit'
            href='mailto:icke@cometotheloop.de?body=My custom mail body'
          >
            dabei!
          </Button> */}
        </div>
        <p className='sub-p'>
          Und solltet ihr "vertrauliche" Fragen vor der Hochzeit haben, wendet
          euch gerne an unsere Trauzeugen.
        </p>
        <div className='two'>
          <div className='box1'>
            <h5>Särah Moll</h5>
            <div className='picWrapper'>
              <StaticImage
                src='../images/sarah.jpeg'
                alt='der Pete'
                style={{ borderRadius: 5 }}
              ></StaticImage>
            </div>
            <p>
              <br></br>
              <br></br>
              ...für mich schon als ca. 6 Jährige die coolste Socke der Welt,
              mein Singstar, Karnevalskönigin, beste Wingwoman, meine Sonne und
              treuste Seele der Welt.<br></br>+49 176 22813742
            </p>
            <div className='two'>
              <IconButton href='mailto:mollsarah89@gmail.com?body=My custom mail body'>
                <SendRoundedIcon></SendRoundedIcon>
              </IconButton>
              <IconButton href='tel:+4917622813742'>
                <PhoneIphoneIcon></PhoneIphoneIcon>
              </IconButton>
            </div>
          </div>
          <div className='box1'>
            <h5>Pete Steinmetz</h5>
            <div className='picWrapper'>
              <StaticImage
                src='../images/peter.jpg'
                alt='der Pete'
                style={{ borderRadius: 5 }}
              ></StaticImage>
            </div>
            <p>
              <br></br>
              <br></br>
              ...hatte die beste Lucky Luke und Asterix Sammlung der Gegend. Und
              dann noch die gemeinsame Schulzeit, Studium, Erasmus, Berge...
              Bester Mann, bester Wingman und verlässlicher Freund.<br></br>+49
              179 4990301
            </p>
            <div className='two'>
              <IconButton href='mailto:peter.steinmetz@web.de?body=My custom mail body'>
                <SendRoundedIcon></SendRoundedIcon>
              </IconButton>
              <IconButton href='tel:+491794990301'>
                <PhoneIphoneIcon></PhoneIphoneIcon>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="two">

    </div> */}
    <div className='blueDiv'>
      <div className='footer'>
        <div className='divContent'>
          <br></br>
          <p className='sub-p'>
            Nach der Feier wird es hier ein Album und Uploadmöglichkeiten für
            eure Bilder geben. Vor der Feier könnt ihr uns schon sagen welcher
            Song auf keinen Fall auf der Party fehlen darf.
          </p>
          {/* <Button
            disabled
            variant='outlined'
            size='large'
            startIcon={<PhotoCameraRoundedIcon />}
            href='coming soon'
          >
            Photoalbum
          </Button> */}
          <div className='two'>
            <IconButton href='https://photos.app.goo.gl/CH2yKkv9fHCmLDqx8'>
              <PhotoCameraRoundedIcon></PhotoCameraRoundedIcon>
            </IconButton>
            <IconButton href='https://open.spotify.com/playlist/3LNdrSUYUBqIxJTlyIQdP7?si=edb0b78ecc9348a4'>
              <LibraryMusicRoundedIcon></LibraryMusicRoundedIcon>
            </IconButton>
          </div>
          <br></br>
          <div className='two'>
            <Button variant='outlined' onClick={() => logout()}>
              ausloggen
            </Button>
          </div>
        </div>
        <div
          style={{
            width: 200,
            margin: 50,
          }}
        >
          <StaticImage src='../images/LooP.svg' alt='Loop Logo'></StaticImage>
        </div>
      </div>
    </div>
  </div>
)

const IndexPage: React.FC = () => {
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false)
  // const [bigOnesCount, setBigOnesCount] = useState(1)
  // const [littleOnesCount, setLittleOnesCount] = useState(0)

  const [usernameVal, setUsernameVal] = useState('')
  const [passwordVal, setPasswordVal] = useState('')
  const [loginErrorMsg, setLoginErrorMsg] = useState('')

  const login = (username, password) => {
    // TODO: do the auth magic
    if (username === 'LooP' && password === 'letmein') {
      setLoginErrorMsg(null)
      setUserIsLoggedIn(true)
    } else {
      setLoginErrorMsg('Wroooooooooong!')
    }
  }

  const logout = () => {
    setUserIsLoggedIn(false)
  }

  return userIsLoggedIn
    ? renderPageContent(
        logout,
        // bigOnesCount,
        // setBigOnesCount,
        // littleOnesCount,
        // setLittleOnesCount,
      )
    : renderLoginForm(
        login,
        usernameVal,
        setUsernameVal,
        passwordVal,
        setPasswordVal,
        loginErrorMsg,
      )
}

export default IndexPage
