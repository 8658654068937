import 'leaflet/dist/leaflet.css'
// const L = require('leaflet')
import { Icon } from 'leaflet/src/layer/marker/Icon'
import { IconDefault } from 'leaflet/src/layer/marker/Icon.Default'

import MarkerHeartIcon from '../images/map-marker-heart.svg'
import MarkerRingsIcon from '../images/map-marker-rings.svg'
import MarkerOarsIcon from '../images/map-marker-oars.svg'
import MarkerHeartIconShadow from '../images/map-marker-heart-shadow.svg'
import MarkerOarsIconShadow from '../images/map-marker-oars-shadow.svg'
import MarkerRingsIconShadow from '../images/map-marker-rings-shadow.svg'

var heartIcon = IconDefault
var oarsIcon = IconDefault
var ringsIcon = IconDefault

if (typeof window !== 'undefined') {
  heartIcon = new Icon({
    iconUrl: MarkerHeartIcon,
    iconSize: [30, 40],
    iconAnchor: [15, 40],

    shadowUrl: MarkerHeartIconShadow,
    shadowSize: [40, 44],
    shadowAnchor: [2, 42],
    popupAnchor: [0, -40],
  })

  ringsIcon = new Icon({
    iconUrl: MarkerRingsIcon,
    iconSize: [30, 40],
    iconAnchor: [15, 40],

    shadowUrl: MarkerRingsIconShadow,
    shadowSize: [40, 44],
    shadowAnchor: [2, 42],
    popupAnchor: [0, -40],
  })

  oarsIcon = new Icon({
    iconUrl: MarkerOarsIcon,
    iconSize: [30, 40],
    iconAnchor: [15, 40],

    shadowUrl: MarkerOarsIconShadow,
    shadowSize: [40, 44],
    shadowAnchor: [2, 42],
    popupAnchor: [0, -40],
  })
}

// export default heartIcon
export { ringsIcon, heartIcon, oarsIcon }
